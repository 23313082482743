@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: orange; }
}

.typewriter, .alternate {
  font-family: 'Courier New', Courier, monospace;
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid orange; /* Cursor simulation */
  animation: typing 4s steps(40, end) infinite, blink-caret 1s step-end infinite;
}



